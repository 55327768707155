let baseUrl = "https://ymexamapi.xichengnet.cn"
let examId = "1696567601688010754"
let appId = "wx5126ab91afea32b9"
let levelText = {
    level1: "小满品牌官<br>要对品牌知其所以然",
    level2: "小满品牌官<br>需要善于倾听的双耳",
    level3: "小满品牌官<br>需要机智的脑瓜",
    level4: "小满品牌官<br>需要超强眼力",
    sutext1: "真挚爱意<br />守护小满",
    sutext2: "满怀热爱<br />守护小满",
    sutext3: "一往无前<br />守护小满",
    sutext4: "全心全意<br />守护小满",
}


export {
    baseUrl,
    examId,
    levelText,
    appId
}