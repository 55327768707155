<template>
  <div class="layout home">
    <img src="../assets/images/slogan.png" />
    <div class="btns">
      <p><button @click="go">开启挑战</button></p>
      <p><button @click="showRule">游戏规则</button></p>
    </div>
    <rule ref="rule"></rule>
  </div>
</template>

<script>
// @ is an alias to /src
import rule from "../components/rule";
import { appId } from "../../public/config"

export default {
  name: "HomeView",
  components: {
    rule,
  },
  data() {
    return {
      code: null,
    };
  },
  methods: {
    showRule() {
      this.$refs.rule.show = true;
    },
    go() {
      // this.$router.push("/user");
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5126ab91afea32b9&redirect_uri=https://h5exam.xichengnet.cn/user&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    },
  },
  created(){
    console.log(appId)
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "FZLTCHJW";
  src: url("../assets/font/FZLTCHJW.TTF");
}
.home {
  position: relative;
  background: transparent url("../assets/images/bg.png") no-repeat scroll 0 0;
  background-size: 100% 100%;
  img {
    width: 74vw;
    height: auto;
    margin: 11vh auto 0;
  }
  .btns {
    position: absolute;
    text-align: center;
    bottom: 10vh;
    width: 100%;
    button {
      width: 150px;
      height: 40px;
      background: #1a49d6;
      box-shadow: 6px 0px 15px 0px rgba(48, 91, 220, 0.3);
      opacity: 1;
      font-family: "FZLTCHJW";
      font-size: 18px;
      color: #fff;
      border: none;
    }
    p {
      margin: 0;
      padding: 0;
      & + p {
        margin-top: 10px;
      }
    }
  }
}
</style>
