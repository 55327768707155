import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null, //用户信息
        paperId: null, //试卷ID
        tmList: {}
    },
    getters: {},
    mutations: {
        SET_USER: (state, data) => {
            state.user = data
        },
        SET_PAPERID: (state, data) => {
            state.paperId = data
        },
        SET_TMLIST: (state, data) => {
            state.tmList = data
        }
    },
    actions: {},
    modules: {}
})